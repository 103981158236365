import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "upload-profile-modal" };
const _hoisted_2 = { class: "modal-headline f-inter" };
const _hoisted_3 = { class: "upload-instructions" };
const _hoisted_4 = { class: "main-container" };
const _hoisted_5 = { class: "preview-container" };
const _hoisted_6 = ["src", "alt"];
const _hoisted_7 = { class: "button-container" };
const _hoisted_8 = { class: "buttons" };
const _hoisted_9 = { class: "mobile-only" };
const _hoisted_10 = { class: "desktop-only" };
const _hoisted_11 = { class: "mobile-only" };
const _hoisted_12 = { class: "desktop-only" };
const _hoisted_13 = { class: "hint" };
const _hoisted_14 = { class: "actions-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DefaultProfilePic = _resolveComponent("DefaultProfilePic");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_file_input = _resolveComponent("file-input");
    const _component_horizontal_separator = _resolveComponent("horizontal-separator");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, {
        show: _ctx.showModal,
        mode: 'light',
        "mobile-drawer": true,
        "show-close-button": false,
        "custom-styles": { modal: { 'padding': '2.5rem' } },
        onClosed: _cache[5] || (_cache[5] = () => _ctx.handleClosedModal())
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.t.title), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t.instructions), 1),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        (_ctx.photoPreview)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _ctx.photoPreview,
                                alt: _ctx.photoAlt,
                                class: "preview"
                            }, null, 8, _hoisted_6))
                            : (_openBlock(), _createBlock(_component_DefaultProfilePic, {
                                key: 1,
                                class: "preview"
                            }))
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_file_input, {
                                name: "photo",
                                accept: "image/png, image/jpeg, image/jpg",
                                "onUpdate:file": _cache[0] || (_cache[0] = ($event) => (_ctx.photoUpdated($event))),
                                "onUpdate:preview": _cache[1] || (_cache[1] = ($event) => (_ctx.photo.preview = $event))
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_ZButton, {
                                        variety: "cyclops",
                                        wide: "mobile-only"
                                    }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t.add), 1),
                                            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t.addPhoto), 1)
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_ZButton, {
                                variety: "cyclops",
                                wide: "mobile-only",
                                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.deletePhoto()))
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t.delete), 1),
                                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t.deletePhoto), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t.hint), 1)
                    ])
                ]),
                _createVNode(_component_horizontal_separator),
                _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_ZButton, {
                        variety: "phoenix",
                        wide: "mobile-only",
                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.handleClosedModal()))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t.cancel), 1)
                        ]),
                        _: 1
                    }),
                    _createVNode(_component_ZButton, {
                        variety: "optimus",
                        wide: "mobile-only",
                        disabled: !_ctx.photoChanged,
                        onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.savePhoto()))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t.save), 1)
                        ]),
                        _: 1
                    }, 8, ["disabled"])
                ])
            ])
        ]),
        _: 1
    }, 8, ["show"]));
}
