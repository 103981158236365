import { defineComponent } from 'vue';
import { useUpload } from '@composables/upload';
export default defineComponent({
    name: 'FileInput',
    props: {
        name: {
            type: String,
            required: true,
        },
        accept: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Note: only listed as a prop to allow using v-bind:file if desired for changes to the file, the prop is
         * not used internally.
         */
        file: {
            type: Object,
            default: null,
        },
        /**
         * Note: only listed as a prop to allow using v-bind:preview if desired for changes to the file, the prop is
         * not used internally.
         */
        preview: {
            type: String,
            default: '',
        }
    },
    emits: ['update:file', 'update:preview'],
    setup(props, context) {
        const { fileInput, onClick, onChange } = useUpload({
            disabled: props.disabled,
            emit: context.emit
        });
        return {
            fileInput,
            onClick,
            onChange,
        };
    },
});
