import { ref } from "vue";
export const useUpload = ({ disabled, emit }) => {
    const fileInput = ref(null);
    const onClick = () => {
        if (disabled) {
            return;
        }
        fileInput.value?.click();
    };
    const onChange = () => {
        if (!fileInput.value?.files?.length) {
            return;
        }
        const file = fileInput.value.files[0];
        if (!file) {
            return;
        }
        emit('update:file', file);
        if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
            emit('update:preview', '');
            return;
        }
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            const preview = typeof fileReader.result === 'string' ? fileReader.result : '';
            emit('update:preview', preview);
        }, false);
        fileReader.readAsDataURL(file);
        // reset the input so that the next change event is triggered properly, even if it is the same file - any
        // listeners will already have the file reference
        fileInput.value.value = '';
    };
    return {
        fileInput,
        onClick,
        onChange,
    };
};
