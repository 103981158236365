import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["name", "disabled", "accept"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("input", {
            ref: "fileInput",
            class: "input",
            name: _ctx.name,
            type: "file",
            disabled: _ctx.disabled,
            accept: _ctx.accept,
            onChange: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.onChange && _ctx.onChange(...args)))
        }, null, 40, _hoisted_1),
        _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.onClick && _ctx.onClick(...args)))
        }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
    ], 64));
}
