import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue";
const _hoisted_1 = ["src", "srcset", "alt"];
const _hoisted_2 = {
    key: 3,
    class: "edit-mode"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DefaultProfilePic = _resolveComponent("DefaultProfilePic");
    const _component_CameraSvg = _resolveComponent("CameraSvg");
    const _component_ZButton = _resolveComponent("ZButton");
    const _component_UploadProfilePictureModal = _resolveComponent("UploadProfilePictureModal");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", {
            class: _normalizeClass(["profile-image-container", [_ctx.backgroundClass, { small: _ctx.small }]])
        }, [
            (_ctx.profilePhotoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.profilePicThumbnail(_ctx.profilePhotoUrl, 146),
                    srcset: _ctx.profilePicThumbnail(_ctx.profilePhotoUrl, 146) + ' 1x,' +
                        _ctx.profilePicThumbnail(_ctx.profilePhotoUrl, 292) + ' 2x',
                    alt: _ctx.t.profileImageAlt,
                    class: "profile-image-img",
                    style: _normalizeStyle({ '--bg-color': _ctx.themeColor })
                }, null, 12, _hoisted_1))
                : (_openBlock(), _createBlock(_component_DefaultProfilePic, {
                    key: 1,
                    class: _normalizeClass([_ctx.defaultImageClass, { small: _ctx.small }])
                }, null, 8, ["class"])),
            (_ctx.instructorType)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "instructor-type",
                    style: _normalizeStyle({ '--bg-color': _ctx.themeColor })
                }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.instructorType), 1)
                ], 4))
                : _createCommentVNode("", true),
            (_ctx.editMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_ZButton, {
                        variety: "phoenix",
                        wide: "never",
                        rounded: "always",
                        class: "edit-mode-button",
                        onClick: _ctx.openUploadModal
                    }, {
                        icon: _withCtx(() => [
                            _createVNode(_component_CameraSvg, { class: "camera-svg" })
                        ]),
                        _: 1
                    }, 8, ["onClick"])
                ]))
                : _createCommentVNode("", true)
        ], 2),
        (_ctx.editMode)
            ? (_openBlock(), _createBlock(_component_UploadProfilePictureModal, {
                key: 0,
                t: _ctx.t.modal,
                "show-modal": _ctx.showModal,
                "handle-closed-modal": () => _ctx.showModal = false,
                "photo-alt": 'Instructor Profile',
                "photo-url": _ctx.profilePhotoUrl,
                "onUpdate:photo": _ctx.handleSavedPhoto
            }, null, 8, ["t", "show-modal", "handle-closed-modal", "photo-url", "onUpdate:photo"]))
            : _createCommentVNode("", true)
    ], 64));
}
