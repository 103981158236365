import { defineComponent, ref, computed } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import { profilePicThumbnail } from '@ts/Util/profile_pic';
import DefaultProfilePic from "@images/default-profile-pic.svg";
import ZButton from '@components/Core/ZButton.vue';
import CameraSvg from '@bx-icons/solid/bxs-camera.svg';
import UploadProfilePictureModal from '@modules/InstructorPage/UploadProfilePictureModal.vue';
import { csrfToken } from '@ts/Util/security';
export const BrandColors = {
    'zumba': 'var(--zumba-light-coral)',
    'strong': '#FF0061'
};
export const translationDefaults = {
    profileImageAlt: "Instructor picture",
    modal: {
        instructions: 'Choose a picture that shows off your face, smile, and glowing personality. This photo will show everywhere your classes appear (including Zumba, STRONG, and other platforms).',
        title: 'Instructor Photo',
        addPhoto: 'Add Photo',
        add: 'Add',
        deletePhoto: 'Delete Photo',
        delete: 'Delete',
        save: 'Save',
        cancel: 'Cancel',
        hint: 'We recommend a square 400x400px image, .jpg or .png',
    }
};
export default defineComponent({
    name: 'ProfilePicture',
    components: {
        DefaultProfilePic,
        ZButton,
        CameraSvg,
        UploadProfilePictureModal
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        profilePhotoUrl: {
            type: String,
            default: '',
            required: false
        },
        instructorType: {
            type: String,
            required: false,
            default: ''
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        savePhotoAction: {
            type: String,
            required: false,
            default: ''
        },
        backgroundClass: {
            type: String,
            required: false,
            default: ''
        },
        themeColor: {
            type: String,
            required: false,
            default: ''
        },
        instructorPID: {
            type: String,
            required: false,
            default: ''
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'photo:updated'
    ],
    setup(props, context) {
        const showModal = ref(false);
        const openUploadModal = () => showModal.value = true;
        const handleSavedPhoto = async (photoPreview) => {
            if (!props.savePhotoAction) {
                throw Error('Invalid URL for saving photo');
            }
            const formData = new FormData();
            const file = typeof photoPreview.file !== 'symbol' ? photoPreview.file : null;
            formData.append('photoPreview', file);
            try {
                const request = await fetch(props.savePhotoAction, {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-CSRF-Token': csrfToken(),
                    },
                    body: formData
                });
                const resp = await request.json();
                if (!request.ok || !resp.success) {
                    throw Error('Error while uploading instructor photo');
                    return;
                }
                showModal.value = false;
                context.emit('photo:updated', resp.cover_photo);
            }
            catch (err) {
                console.log(err);
                throw Error('Error while uploading instructor photo');
            }
        };
        const defaultImageClass = computed(() => {
            if (props.themeColor === BrandColors.strong) {
                return 'default-strong';
            }
            return 'default-zumba';
        });
        return {
            showModal,
            openUploadModal,
            profilePicThumbnail,
            handleSavedPhoto,
            defaultImageClass
        };
    }
});
