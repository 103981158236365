import { computed, defineComponent, reactive, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n';
import DefaultProfilePic from "@images/default-profile-pic.svg";
import Modal from '@components/Core/Modal.vue';
import FileInput from '@components/FormFields/FileInput/FileInput.vue';
import ZButton from '@components/Core/ZButton.vue';
import { profilePicThumbnail } from '@ts/Util/profile_pic';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
/**
 * Value to use for empty or unchanged file, meaning there is no file to be uploaded
 */
const NoUploadFile = Symbol();
const translationDefaults = {
    instructions: 'Choose a picture that shows off your face, smile, and glowing personality. This photo will show everywhere your classes appear (including Zumba, STRONG, and other platforms).',
    title: 'Instructor Photo',
    addPhoto: 'Add Photo',
    add: 'Add',
    deletePhoto: 'Delete Photo',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel',
    hint: 'We recommend a square 400x400px image, .jpg or .png',
};
export default defineComponent({
    name: 'UploadProfilePictureModal',
    components: {
        Modal,
        DefaultProfilePic,
        FileInput,
        ZButton,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value) => validateTranslations(value, translationDefaults)
        },
        photoUrl: {
            type: String,
            required: false,
            default: ''
        },
        photoAlt: {
            type: String,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        handleClosedModal: {
            type: Function,
            required: true
        }
    },
    emits: ['update:photo'],
    setup(props, context) {
        const show = ref(props.showModal);
        const photoChanged = ref(false);
        // Set up the profile upload
        const photo = reactive({
            file: NoUploadFile,
            preview: props.photoUrl?.valueOf() || '',
            error: '',
        });
        const deletePhoto = () => {
            photoChanged.value = true;
            photo.file = NoUploadFile;
            photo.preview = '';
        };
        const savePhoto = () => {
            photoChanged.value = false;
            context.emit('update:photo', photo);
        };
        const photoPreview = computed(() => {
            return photo.preview ? profilePicThumbnail(photo.preview, 80) : '';
        });
        const photoUpdated = ($event) => {
            photo.file = $event;
            photoChanged.value = true;
        };
        return {
            show,
            deletePhoto,
            photo,
            photoPreview,
            photoUpdated,
            photoChanged,
            savePhoto
        };
    },
});
